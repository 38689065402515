import { SetValue } from '../types';

export type TraceResult = Partial<{
  fl: string;
  h: string;
  ip: string;
  ts: string;
  visit_scheme: string;
  uag: string;
  colo: string;
  http: string;
  loc: string;
  tls: string;
  sni: string;
  warp: string;
}>;

export function trace() {
  return fetch('https://cloudflare.com/cdn-cgi/trace')
    .then(res => res.text())
    .then(body => createTraceResult(body))
    .catch(e => {
      // We just want to suppress errors
      console.error(e);
    });
}

export function createTraceResult(data: string): TraceResult {
  return data
    .split('\n')
    .map(line => line.split('='))
    .reduce((accum, [key, val]) => Object.assign(accum, { [key]: val }), {});
}
export const EUCountries = new Set([
  'AT',
  'BE',
  'BG',
  'HR',
  'CY',
  'CZ',
  'DK',
  'EE',
  'FI',
  'FR',
  'DE',
  'GR',
  'HU',
  'IE',
  'IT',
  'LV',
  'LT',
  'LU',
  'MT',
  'NL',
  'PL',
  'PT',
  'RO',
  'SK',
  'SI',
  'ES',
  'SE',
  'GB',
  'CH',
  'LI',
  'IS',
  'NO',
] as const);

export type EUCountry = SetValue<typeof EUCountries>;

export function inEU(location: Required<TraceResult>['loc']): location is EUCountry {
  return EUCountries.has(location as any);
}
