import { useLocation } from '@reach/router';

const UTMParamMap = {
  campaign: 'utm_campaign',
  source: 'utm_source',
  medium: 'utm_medium',
  content: 'utm_content',
};

export function useUTMParams() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  return {
    campaign: params.get(UTMParamMap.campaign),
    source: params.get(UTMParamMap.source),
    medium: params.get(UTMParamMap.medium),
    content: params.get(UTMParamMap.content),
  };
}

export type UTMParams = ReturnType<typeof useUTMParams>;

export function isUTMParam(key: string): key is keyof UTMParams {
  return key in UTMParamMap;
}
