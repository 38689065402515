import React from 'react';
import { Input, Label, Span } from '../Elements';
import { MarkdownBlock } from '../MarkdownBlock';
import './Checkbox.scss';

interface CheckboxProps {
  checked: boolean;
  labelText: string;
  labelClassName?: string;
  id: string;
  labelHtmlFor: string;
  value: string | number | string[] | undefined;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  labelText,
  id,
  labelClassName,
  labelHtmlFor,
  onChange,
  value,
}) => {
  return (
    <Label
      paddingLeft={4}
      marginBottom={2}
      display="block"
      position="relative"
      htmlFor={labelHtmlFor}
      className={`${labelClassName || 'body-2'} pointer br-checkbox`}
    >
      <MarkdownBlock source={labelText} />
      <Input
        hidden
        type="checkbox"
        id={id}
        checked={checked}
        name={id}
        value={value}
        onChange={onChange as any}
      />
      <Span
        border="all"
        borderColor="gray1"
        borderWidth={1}
        height={3}
        width={3}
        position="absolute"
        className="checkmark"
      ></Span>
    </Label>
  );
};
