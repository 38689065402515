import React, { ComponentType, FC, useState } from 'react';
import { useStaticStrings } from '../../../common/hooks/useStaticStrings';
import { ButtonCTA } from '../Button';
import { CFYesCheck } from '../CFIcons/CFYesCheck';
import { Div, H2, P } from '../Elements';
import { MarketoFormEmbedded } from '../../../contentful/content-types/marketo-form-embedded';

export interface SuccessElementProps {
  ctaAction: () => void;
  form: MarketoFormEmbedded;
}

export const OnSuccessMessage: FC<SuccessElementProps> = props => {
  const {
    marketoFormCustomSuccessMessage,
    marketoFormThankYouText,
    marketoFormAssetDownloadButtonText,
    marketoFormPdfDownload,
  } = props.form;
  const staticStrings = useStaticStrings();
  const thankYou = marketoFormThankYouText
    ? marketoFormThankYouText
    : staticStrings.json_values['Thank_You'];
  const message = marketoFormCustomSuccessMessage
    ? marketoFormCustomSuccessMessage
    : staticStrings.json_values['Someone_from_Cloudflare_will_be_in_touch_with_you_shortly_'];
  const pdfDownloadButtonText = marketoFormAssetDownloadButtonText
    ? marketoFormAssetDownloadButtonText
    : 'Download Now';
  const defaultButtonText = staticStrings.json_values['Close_window'];

  //do we neeed to translate?

  return (
    <Div marginBottom={6}>
      <Div lineHeight={1} marginBottom={2}>
        <CFYesCheck size={64} color="blue1" />
      </Div>
      <H2 lineHeight="title" marginBottom={2}>
        {thankYou}
      </H2>
      <P fontWeight={4} fontSize={2} lineHeight="copy" marginBottom={[6, 7]}>
        {message}
      </P>
      {marketoFormPdfDownload && (
        <Div marginBottom={6}>
          <ButtonCTA
            href={marketoFormPdfDownload ? marketoFormPdfDownload.file?.publicURL : '#'}
            buttonColor="blue-outline"
            buttonStyle="narrow"
            color="blue"
            onClick={props.ctaAction}
          >
            {marketoFormPdfDownload ? pdfDownloadButtonText : defaultButtonText}
          </ButtonCTA>
        </Div>
      )}
    </Div>
  );
};
