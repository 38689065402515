import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Button, Div, Img, Span } from '../Elements';
interface Company {
  name: string;
  domain: string;
  logo: string;
}
const ClearbitAutoComplete: React.FC<{
  value: string;
  setData: (company: string, url: string) => void;
}> = ({ value, setData }) => {
  const [results, setResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isVisible, toggleDropdown] = useState(true);
  const ref = useRef<HTMLDivElement>(null);
  const [companySelected, setCompanySelected] = useState<string>('');

  const debouncedSearch: (query: string) => void = useRef(
    _.debounce((searchQuery: string) => {
      search(searchQuery).then(results => {
        setResults(results);
      });
    }, 1000)
  ).current;

  useEffect(() => {
    if (value && value !== companySelected) {
      debouncedSearch(value);
    } else {
      setResults([]);
    }
  }, [value]);

  useEffect(() => {
    const onClickOutside = (e: MouseEvent) => {
      if (ref.current != null && !ref?.current?.contains(e.target as any)) {
        setResults([]);
      }
    };
    window.addEventListener('click', onClickOutside);
    return () => {
      window.removeEventListener('click', onClickOutside);
    };
  }, []);

  function search(query: string) {
    return fetch(`https://autocomplete.clearbit.com/v1/companies/suggest?query=${query}`).then(r =>
      r.json()
    );
  }

  if (results.length > 0) {
    return (
      <div
        className="autocomplete-suggestions"
        style={{ display: 'block', width: '100%' }}
        ref={ref}
      >
        {results.map((company: Company) => {
          return (
            <Div
              width={'100%'}
              role="button"
              textAlign={'left'}
              display={'flex'}
              alignItems={'center'}
              key={company.name}
              className="autocomplete-suggestion"
              data-name={company.name}
              backgroundColor="white"
              border="none"
              hovered={{ backgroundColor: 'black-025' }}
              onClick={() => {
                setData(company.name, company.domain);
                setResults([]);
                setCompanySelected(company.name);
              }}
            >
              <Div className="flex">
                <Img src={company.logo} marginRight={2} height={3} />
              </Div>{' '}
              <Div>{company.name}</Div>
            </Div>
          );
        })}
      </div>
    );
  }
  return null;
};

export default ClearbitAutoComplete;
