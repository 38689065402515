export type DataType =
  | 'text'
  | 'textArea'
  | 'telephone'
  | 'email'
  | 'select'
  | 'htmltext'
  | 'hidden'
  | 'checkbox';

// export type DataType =
//   | 'string'
//   | 'phone'
//   | 'email'
//   | 'picklist'
//   | 'htmltext'
//   | 'textarea'
//   | 'single_checkbox'
//   | 'hidden'
//   | 'profile';

// export interface MarketoFormValidationField {
//   Datatype: DataType;
//   InputLabel: string;
//   IsRequired: boolean;
//   Name: string;
//   ValidationMessage: string;
//   MaxLength: number;
// }

// export interface MarketoFormInformation {
//   dataFields: MarketoFormValidationField[];
//   Id: number;
// }

export type MarketoFormField = {
  id: string;
  label?: string;
  dataType: DataType;
  rowNumber?: number;
  columnNumber?: number;
  validationMessage?: string;
  fieldMetaData?: {
    [key: string]: any;
  };
  required: boolean;
  text?: string;
  formPrefill?: boolean;
  visibilityRules?: { ruleType: 'alwaysShow' | 'show'; rules?: [] };
};

export type MarketoFormData = {
  id: number;
  url: string;
  buttonLabel: string;
  waitingLabel: string;
};

export type MarketoFormAPIResult = {
  fields: MarketoFormField[];
  form: MarketoFormData;
};

export type FormPayload = {
  hidden: any[];
  fields: MarketoFormField[];
  form: MarketoFormData;
};

const getMarketoFormData = async (formId: number, baseApi: string): Promise<FormPayload | null> => {
  if (!formId) {
    console.log('Could not fetch marketo form data - missing form id');
    return null;
  }

  try {
    const result = await fetch(`${baseApi}/marketo/form/${formId}`);
    const data: MarketoFormAPIResult = await result.json();

    const payload: Pick<FormPayload, 'hidden' | 'fields'> = {
      hidden: [],
      fields: [],
    };

    const { fields, form } = data;

    for (let i = 0; i < fields.length; i++) {
      let d = fields[i];
      if (d.dataType === 'hidden') {
        payload.hidden.push(d);
      } else {
        if (!d.label) {
          if (d.dataType === 'checkbox' && i + 1 < fields.length) {
            // Get checkbox label from next element
            const next = fields[i + 1];
            d.label = next.dataType === 'htmltext' ? next.text : next.label;
            payload.fields.push(d);
            i = i + 1; // We've processed the next element already, skip ahead
          }
        } else {
          payload.fields.push(d);
        }
      }
    }

    return { ...payload, form };
  } catch (e) {
    console.warn('Could not fetch marketo form data', e);
    return null;
  }
};

export { getMarketoFormData };
