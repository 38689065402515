import { userInBlockedCountry } from '../../../common/util/blocked-countries';
import { MarketoFormField } from './get-form-data';

export const validateEmail = (maybeEmail: string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(maybeEmail);
};

export const validatePhone = (maybeNumber: string) => {
  return /^[ 0-9_@./#&+-]*$/.test(maybeNumber);
};

export const validateInput = (data: MarketoFormField, value: string) => {
  const { required, dataType } = data;
  if (value !== null && value !== undefined && typeof value !== 'string') {
    return true;
  }
  if (data.id === 'Country') {
    return !userInBlockedCountry(value);
  }
  if (!required) {
    return true;
  }
  if (!value) {
    return false;
  }
  if (dataType === 'email') {
    return validateEmail(value);
  }

  if (dataType === 'telephone') {
    return validatePhone(value);
  }

  return value.length >= 0;
};
